* {
    padding: 0;
    margin: 0;
    border: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

li::marker {
    display: none;
}

:focus,
:active {
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

a:focus,
a:active {
    outline: none;
}

html,
body {
    margin: 0 auto;
    font-size: 100%;
    font-size: 1rem;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    //-webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    color: var(--color-white);
}

input,
button,
textarea {
    font-family: inherit;
}

input::-ms-clear {
    display: none;
}

button {
    cursor: pointer;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a,
a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

ul li {
    list-style: none;
}

img {
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-size: inherit;
    font-weight: inherit;
}
