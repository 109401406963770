.ConnectButton {
    &.black {
        background: var(--bg-200, #18181b);
        color: var(--color-white);
    }

    &.mint {
        background: var(--bg-200, #18181b);
        color: var(--color-white);
        transform: translate(0);
        transform-style: preserve-3d;
        //width: 100%;
        //max-width: 280px;

        &:disabled {
            opacity: 1;
            pointer-events: none;
        }

        [data-sh] {
            position: absolute;
            inset: 0px;
            transform: translateZ(-1px);

            &:before {
                border-radius: 48px;
                content: "";
                position: absolute;
                transform: translate3d(0, 0, -1px);
                inset: 0px;
                background: linear-gradient(90deg, #f79f1f 0%, #00c6e0 100%);
                filter: blur(20px);
                transition: inset 0.5s ease;
            }
        }

        &:hover {
            box-shadow: none;

            [data-sh] {
                &:before {
                    inset: -10px;
                }
            }
        }
    }

    &.connect {
        background: var(--bg-200, #18181b);
        color: var(--color-white);
        transform: translate(0);
        transform-style: preserve-3d;
        width: 100%;
        //max-width: 280px;

        &:disabled {
            opacity: 1;
            pointer-events: none;
        }

        [data-sh] {
            position: absolute;
            inset: 0px;
            transform: translateZ(-1px);

            &:before {
                border-radius: 48px;
                content: "";
                position: absolute;
                transform: translate3d(0, 0, -1px);
                inset: 0px;
                background: linear-gradient(90deg, #f79f1f 0%, #c3c 100%);
                filter: blur(20px);
                transition: inset 0.5s ease;
            }
        }

        &:hover {
            box-shadow: none;

            [data-sh] {
                &:before {
                    inset: -10px;
                }
            }
        }
    }
}

@media screen and (min-width: 706px) {
    .ConnectButton {

        &.connect,
        &.mint {
            width: 232px;
        }
    }
}

.connected {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    overflow: hidden;

    &.noHeader {
        width: fit-content;
        background-color: var(--bg-300);
        padding: 12px 24px 12px 16px;
        border-radius: 16px;
        z-index: 5;
        //position: relative;
        transform: translate(0);
        transform-style: preserve-3d;
    }
}