.SolanaModal {
    .scrollInfo {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .slider {
        width: 100%;
        border-radius: 10px;
        background: rgba(63, 63, 70, 0.3);
        display: flex;
        padding: 4px;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        margin-bottom: 8px;
        margin-top: 32px;

        .sliderItem {
            font-weight: 600;
            border-radius: 8px;
            display: flex;
            padding: 8px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            cursor: pointer;
            transition: all 0.3s ease;

            img {
                width: 24px;
                height: 24px;
            }

            &.active {
                color: black;
                background: var(--text-100, #fff);
            }
        }
    }

    .discordButton {
        align-self: center;
        gap: 16px;
        color: black !important;
    }

    h3,
    h4,
    .item {
        text-align: left !important;
    }

    h4 {
        padding-right: 0 !important;
    }

    h3 {
        display: none;
    }

    .list {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 99%;
        margin-bottom: 24px;
    }

    .item {
        width: 99%;
        display: flex;
        //align-items: center;
        gap: 16px;
        align-items: baseline;
    }

    .number {
        color: var(--text-100, #fff);
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        border-radius: 40px;
        background: rgba(63, 63, 70, 0.3);
        display: flex;
        width: 32px;
        height: 32px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }

    .info,
    .info1 {
        width: 100%;
        color: var(--text-100, #fff);
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
    }

    .info1 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: calc(100% - 40px);
    }

    .copyAddress {
        display: flex;
        gap: 10px;
        align-items: center;
        align-self: center;
        width: 100%;
        cursor: pointer;

        span {
            padding: 12px;
            text-overflow: ellipsis;
            color: var(--text-100, #fff);
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            border-radius: 8px;
            background: var(--primary-100, #06f);
            width: 100%;
            max-width: calc(100% - 10px - 24px - 42px);
            overflow: hidden;
            text-wrap: nowrap;
        }
    }
}

.SolanaButton {
    display: none;
    background: var(--Blue-Ribbon-100, #dbe6fe);
    font-weight: 500 !important;
    color: black;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.12px;
    text-transform: capitalize;
    gap: 8px;
    padding: 8px;
    padding-left: 16px;

    [data-sh] {
        display: none;
    }

    b {
        font-weight: 600;
    }
}

.SolanaMbl {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--bg-100, #101012);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    text-decoration-line: underline;
    cursor: pointer;
}

.text {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.icons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

@media screen and (max-width: 440px) and (max-height: 690px) {
    .SolanaModal {
        .item {
            gap: 8px;
        }

        .discordButton {
            width: 248px;
            box-shadow: none !important;
        }

        .list {
            width: 100%;
        }

        .copyAddress {
            span {
                padding: 8px 12px;
            }
        }

        [data-cross] {
            right: 24px;
            top: 24px;
            background-color: rgba(63, 63, 70, 0.30);

            img {
                filter: invert(0);
            }
        }

        [data-modal-content] {
            justify-content: flex-start;
        }

        .number {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            border-radius: 40px;
            width: 24px;
            height: 24px;
        }

        .slider {
            max-width: calc(100% - 52px);
            margin-top: 0;

            .sliderItem {
                padding: 4px;
            }
        }
    }
}

@media screen and (min-width: 440px) {
    .SolanaModal {
        .slider {
            margin-bottom: 16px;

            .sliderItem {
                padding: 12px 16px;

                img {
                    width: 32px;
                    height: 32px;
                }
            }
        }

        .list {
            gap: 24px;
            margin-bottom: 24px;
        }

        .copyAddress {
            span {
                padding: 12px 16px;
            }
        }
    }
}

@media screen and (min-width: 706px) {
    .SolanaModal {
        .slider {
            gap: 16px;

            .sliderItem {}
        }

        .list {
            margin-bottom: 40px;
        }

        .copyAddress {
            span {
                width: fit-content;
                max-width: calc(100% - 24px - 32px);
                overflow: hidden;
            }
        }
    }

    .SolanaButton {
        display: flex;
    }

    .SolanaMbl {
        display: none;
    }
}

@media screen and (min-width: 1024px) {
    .SolanaButton {
        padding: 10px;
        min-width: 177px;
        height: 48px;
        width: 100%;
    }

    .SolanaModal {
        h4 {
            display: none;
        }

        h3 {
            display: block;
        }
    }
}