section.hero {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 60vw;
}

.hero {
    text-align: center;
    padding-top: var(--spacer) !important;
    display: flex;
    flex-direction: column;

    .header {
        position: relative;
        width: auto;
        align-self: center;

        h1 {
            width: fit-content;
        }
    }

    .label {
        cursor: pointer;
        top: 45px;
        right: -30%;
        transform: rotate(-4deg);
        pointer-events: all !important;
    }

    .star {
        bottom: 50px;
        left: 60px;
    }

    .star2 {
        display: none;
    }

    .sparkle {
        transform: rotate(-14.844deg);
        top: -30px;
        align-self: center;
    }

    .button {
        align-self: center;
        margin-top: 64px;
    }

    .back {
        width: calc(100% + var(--mg-lr));
        height: 150%;
        left: calc(-1 * var(--mg-lr));
        /*  display: none; */
        opacity: 0;
        transition: opacity 0.3s ease-in;

        &.loaded {
            opacity: 1;
        }
    }



    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1000px;
        background-color: var(--bg-100);
        left: 0;
        bottom: -1200px;
    }

    h5 {
        max-width: 960px;
        align-self: center;
    }

    br {
        display: block;
    }

    .content {
        position: relative;
        z-index: 5;
        align-self: center;
        padding: 0 var(--mg-lr);
    }

    .black {
        z-index: 0;
        bottom: -10%;
        width: 110vw;
        align-self: center;

        img {
            filter: blur(5px);
            width: 100%;
            box-shadow: 00 123px 0 100px var(--bg-100);
        }
    }

    .comingmbl {
        z-index: 0;
        bottom: 3%;
        width: 105vw;
        align-self: center;
        opacity: 0;
        transition: opacity 0.3s ease-in 0.2s;

        &.loaded {
            opacity: 1;
        }

        img {
            width: 100%;
        }
    }

    .coming {
        opacity: 0;
        display: none;
        transition: opacity 0.3s ease-in 0.2s;

        &.loaded {
            opacity: 1;
        }

    }
}

@media screen and (min-width: 706px) {

    section.hero {
        padding-bottom: 240px;
    }

    .hero {
        .star2 {
            display: block;
            right: 70px;
            top: -40px;
        }

        .star {
            width: 40px;
            height: 40px;
        }

        .sparkle {
            top: -100px;
        }



        .label {
            top: 50px;
            right: -40%;
        }

        .coming {
            display: block;
            z-index: 0;
            bottom: 15%;
            width: 100vw;

            img {
                width: 100%;
            }
        }

        .comingmbl {
            display: none;

        }

        .black {
            bottom: -20vw;
        }
    }
}

@media screen and (min-width: 1024px) {
    section.hero {
        padding-bottom: 320px;
    }

    .hero {

        .label {
            top: 62px;
            right: -28%;
        }

        .back {
            display: none;
        }

        .star {
            bottom: 50px;
            left: 0px;
        }

        .test,
        .test2 {
            position: absolute;
            --size: 50vw;
            min-width: 700px;
            min-height: 700px;
            width: var(--size);
            height: var(--size);
            filter: blur(calc(var(--size) / 5));
            border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
        }

        .test {
            left: 10%;
            animation: rotate 15s cubic-bezier(0.8, 0.5, 0.2, 0.8) alternate infinite;
            background-image: linear-gradient(rgb(167, 22, 157), rgba(0, 198, 224, 1));
        }

        .test2 {
            right: 10%;
            background-image: linear-gradient(rgba(27, 58, 216, 0.7), rgba(191, 49, 177, 0.793));
            animation: rotate 25s cubic-bezier(0.8, 0.2, 0.2, 0.3) alternate-reverse infinite;
        }



        .coming {
            bottom: 13%;
            max-width: 1600px;
            align-self: center;
        }
    }
}

@media screen and (min-width: 1550px) {
    .hero {
        .black {
            bottom: -25vw;
        }

        .coming {
            bottom: 7%;
            max-width: 1600px;
            align-self: center;
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}