.textLoader {
    display: inline-flex;
    align-items: center;
    color: var(--bg-300);
    margin-bottom: 0 !important;

    svg {
        margin-right: 15px;
        animation: rotation 1s linear infinite;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.LineLoader {
    display: flex;
    align-items: center;
    align-self: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    --color: 221, 221, 221;

    p {
        opacity: 0.3;
    }

    .line {
        width: 200px;
        height: 4px;
        position: relative;
        overflow: hidden;
        background-color: rgba(var(--color), 0.15);
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;

        &:before {
            content: "";
            position: absolute;
            left: -50%;
            height: 4px;
            width: 80px;
            background-color: rgba(var(--color), 1);
            animation: lineAnim 1s linear infinite;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
        }
    }
}

@keyframes lineAnim {
    0% {
        left: -40%;
    }

    50% {
        left: 20%;
        width: 80%;
    }

    100% {
        left: 100%;
        width: 100%;
    }
}
