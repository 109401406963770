.ConnectModal {
    h3 {
        margin-bottom: 10px;
    }

    .cardsBlock {
        display: flex;
        gap: 8px;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
}

.modalBtn {
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    min-height: 112px;

    [data-sh] {
        display: none;
    }

    .main {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        gap: 16px;
    }

    .texts {
        text-align: left;
    }

    [data-img] {
        max-height: 72px;
        width: 72px;
        height: 100%;
    }

    .icons {
        position: relative;
        width: 72px;
        height: 72px;

        .ton,
        .sol {
            position: absolute;
        }

        .sol {
            top: 4px;
            left: 4px;
        }

        .ton {
            bottom: 4px;
            right: 4px;
        }
    }

    color: var(--color-white);
    border-radius: 32px;
    background: rgba(63, 63, 70, 0.4);
    padding: 16px;

    &:hover {
        box-shadow: none;
    }

    p {
        margin-top: -16px;
    }

    .link {
        display: none;
    }
}

@media screen and (min-width: 410px) {
    .ConnectModal {
        h3 {
            margin-bottom: var(--h3-mb);
        }

        .cardsBlock {
            gap: 16px;
        }
    }

    .modalBtn {
        padding: 32px;
    }
}

@media screen and (min-width: 805px) {
    .modalBtn {
        display: flex;
        align-items: center;
        min-height: 344px;
        justify-content: space-between;
        max-width: calc(33% - 4px);
        border-radius: 48px;

        .main {
            flex-direction: column;
        }

        .texts {
            text-align: center;
        }

        .icons {
            display: flex;
            gap: 4px;
            width: 100%;
            align-items: center;
            justify-content: center;

            .ton,
            .sol {
                position: static;
                width: 72px;
                height: 72px;
            }
        }

        .link {
            display: flex;
            align-self: flex-end;
        }

        p {
            margin-bottom: 16px;
        }
    }

    .ConnectModal {
        .cardsBlock {
            gap: 8px;
            flex-wrap: nowrap;
            flex-direction: row;
            width: 100%;
        }
    }
}

@media screen and (min-width: 1100px) {
    .modalBtn {
        min-height: 392px;
        max-width: calc(33% - 8px);

        [data-img] {
            width: 112px;
            max-height: 112px;
            margin-bottom: 24px;
        }

        .icons {
            height: 122px;
            margin-bottom: 24px;

            .sol,
            .ton {
                width: 112px;
                height: 122px;
            }
        }
    }

    .ConnectModal {
        .cardsBlock {
            gap: 16px;
        }
    }
}
