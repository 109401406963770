@keyframes moving1 {
    0% {
        transform: rotate(0deg) translateX(15px) rotate(0deg);
    }

    50% {
        transform: rotate(360deg) translateX(10px) rotate(-360deg);
    }

    100% {
        transform: rotate(0deg) translateX(15px) rotate(0deg);
    }
}

@keyframes moving2 {
    0% {
        transform: rotate(0deg) translateX(4px) rotate(0deg);
    }

    50% {
        transform: rotate(360deg) translateX(20px) rotate(-360deg);
    }

    100% {
        transform: rotate(0deg) translateX(4px) rotate(0deg);
    }
}

@keyframes moving3 {
    0% {
        transform: rotate(0deg) translateX(9px) rotate(0deg);
    }

    50% {
        transform: rotate(360deg) translateX(12px) rotate(-360deg);
    }

    100% {
        transform: rotate(0deg) translateX(9px) rotate(0deg);
    }
}

@keyframes moving4 {
    0% {
        transform: rotate(0deg) translateX(2px) rotate(0deg);
    }

    50% {
        transform: rotate(360deg) translateX(10px) rotate(-360deg);
    }

    100% {
        transform: rotate(0deg) translateX(2px) rotate(0deg);
    }
}

@keyframes floating {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(3%);
        transform: translateY(3%);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes flying {
    0% {
        transform: rotate(50deg) translateX(0px) rotate(-50deg);
    }

    50% {
        transform: rotate(180deg) translateX(20px) rotate(-180deg);
    }

    100% {
        transform: rotate(360deg) translateX(0px) rotate(-360deg);
    }
}

.load {
    animation: load 0.4s ease;
}

@keyframes load {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
