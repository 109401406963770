.MintSuccess {
    .discordButton {
        gap: 16px;
        margin-top: 16px;
        color: black !important;
    }

    a.button {
        background-color: white !important;
        color: black !important;

        img {
            filter: invert(0) !important;
        }
    }

    h3.h3 {
        display: block !important;
        font-size: var(--h3-size) !important;
        line-height: var(--h3-lh) !important;
        text-align: center !important;
        padding-right: 0 !important;
        align-self: center !important;
        max-width: none !important;
    }

    h3,
    h5 {
        z-index: 1;
    }

    h3 {
        padding-right: 24px;
        font-size: 20px;
        line-height: 24px;
    }

    h5 {
        font-size: 16px;
        line-height: 24px;
    }

    .buttonsBlock {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 16px;
        width: 100%;
    }

    .imageWrapper {
        height: 220.16px;
        width: 235.52px;
    }

    .imageContainer {
        position: relative;
        height: 220.16px;
        width: 235.52px;
    }

    .fade {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 1.5s ease-in-out;
    }

    .visible {
        opacity: 1;
    }

    /*    .imagesBlock {
        position: relative;
        //max-width: 100%;
        max-height: 100%;
        max-width: 650px;
        // min-height: 170px;
        width: 100%;

        .card {
            width: 100%;
            height: auto;
            max-height: 100%;
            margin-top: -13vw;
            z-index: 1;
            position: relative;
        }

        .desk {
            display: none;
            transform: rotate(180deg);
        }

        .purple {
            right: 32%;
            top: 0%;
            height: auto;
        }

        .blue {
            left: 5%;
            top: 40%;
            height: auto;
        }

        .small {
            top: 55%;
            right: 15%;
            z-index: 1;
            height: auto;
        }
    }

    h5 {
        margin-top: -10vw;
        //margin-bottom: 48px;
        margin-bottom: 24px;
    }  */

    &.default {
        h3 {
            display: none;
        }

        h4,
        p {
            text-align: left;
            color: white;
        }

        .button {
            width: 100%;
            color: black !important;
        }

        .card {
            margin-top: 20px;
            display: flex;
            padding: 12px 16px;
            align-items: flex-end;
            gap: 16px;
            align-self: stretch;
            border-radius: 16px;
            background: rgba(63, 63, 70, 0.3);
            text-align: left;

            .icon {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 411px) {
    .MintSuccess {
        .imageWrapper {
            height: 344px;
            width: 368px;
        }

        .imageContainer {
            position: relative;
            height: 344px;
            width: 368px;
        }

        &.default {
            .mblImg {
                width: 100%;
                height: auto;
                max-width: 420px;
            }

            .card {
                margin-top: 40px;
            }
        }
    }
}

@media screen and (min-width: 705px) {
    /*  br {
         display: none;
     } */

    .MintSuccess {
        .discordButton {
            margin-top: 32px;
        }

        &.default {
            .dscImg {
                position: absolute;
                right: 0;
                width: 100%;
                height: auto;
                max-width: 350px;
                top: 10px;
            }

            .button {
                width: auto;
                align-self: flex-start;
            }

            p {
                margin-bottom: 40px;
            }

            h4,
            p {
                align-self: flex-start;
                max-width: calc(100% - 350px - 16px);
            }

            .card {
                margin-top: 64px;
                padding: 24px 32px;
                gap: 24px;

                .icon {
                    display: block;
                }
            }
        }

        .buttonsBlock {
            gap: 24px;
            width: auto;
        }

        .imagesBlock {
            width: 100%;

            .card {
                margin-top: -18%;
            }

            .purple {
                right: 32%;
                top: 6%;
                width: 73px;
            }

            .blue {
                left: 6%;
                top: 38%;
                width: 68px;
            }

            .small {
                top: 55%;
                right: 15%;
                width: 40px;
            }
        }

        .imageWrapper {
            position: absolute;
            right: 20px;
            top: 24px;
        }

        /*  h5 {
            margin-top: -14%;
            margin-bottom: 48px;
        }  */
    }
}

@media screen and (min-width: 1024px) {
    .MintSuccess {
        h3 {
            font-size: var(--h3-size);
            line-height: var(--h3-lh);
        }

        &.default {
            h3 {
                display: block;
                align-self: flex-start;
                text-align: left;
            }

            h4 {
                display: none;
            }

            h3,
            p {
                max-width: 480px;
            }

            p {
                margin-bottom: 64px;
            }

            .dscImg {
                top: 56px;
                right: 32px;
                max-width: 395px;
            }

            .card {
                margin-top: 80px;
            }
        }

        .imageWrapper {
            top: 50px;
        }

        /*  .imagesBlock {
            width: 90%;

            .card {
                margin-top: -90px;
            }

            .purple {
                right: 28%;
                top: 0%;
                width: 104px;
            }

            .blue {
                left: 3%;
                top: 28%;
                width: 96px;
            }

            .small {
                top: 40%;
                right: 13%;
                width: 56px;
            }

            .desk {
                display: block;
                width: 40px;
                height: auto;
                top: 45%;
                left: 20%;
                z-index: 2;
            }
        }

        h5 {
            margin-top: -12%;
            margin-bottom: 48px;
        } */
    }
}