.Modal {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: opacity 0.3s ease;
    z-index: -1;

    a,
    a:active,
    a:visited {
        color: inherit;
    }

    &.overflow {
        .content {
            overflow-y: auto;
            overflow-x: hidden;
            justify-content: flex-start;
            max-height: calc(100vh - 100px);
            padding-right: 5px;

            &::-webkit-scrollbar {
                width: 5px;
                /* width of the entire scrollbar */
            }

            &::-webkit-scrollbar-track {
                background: var(--bg-200);
                /* color of the tracking area */
            }

            &::-webkit-scrollbar-thumb {
                /* color of the scroll thumb */
                background: var(--bg-300);
                opacity: 0.4;
                border-radius: 20px;
                /* roundness of the scroll thumb */
                border: none;
                /* creates padding around scroll thumb */
            }
        }
    }

    .overlay {
        width: 100%;
        height: 100%;
        background: rgba(16, 16, 18, 0.48);
        backdrop-filter: blur(24px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
    }

    .wrapper {
        width: 100%;
        position: relative;
        //padding: 32px 32px 40px;
        padding: 24px;
        //margin: 0 var(--mg-lr);
        border-radius: 24px;
        background: var(--bg-200, #18181b);
        color: var(--color-white);
        animation: append-animate 0.3s ease-in-out;
        text-align: center;
        max-width: 100vw;
        max-height: 100vh;

        display: flex;
        height: 100%;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        justify-content: center;

        h3,
        h4 {
            padding-right: 15px;
        }
    }

    &.opened {
        z-index: 9999;
        pointer-events: auto;
        animation: append-animate 0.3s ease-in-out;
        opacity: 1;
    }

    &.isClosing {
        transition: opacity 0.3s ease;
        opacity: 0;
    }
}

.cross {
    height: 40px;
    width: 40px;

    img {
        filter: invert(1);
        width: 18px;
        height: 18px;
    }

    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 1001;
    cursor: pointer;
    pointer-events: all;
    background-color: white;
    border-radius: 50%;
    padding: 11px;
}

@media screen and (min-width: 420px) {
    .Modal {
        .overlay {
            padding: 12px;
        }

        .content {

            h3,
            h4 {
                padding-right: 0px;
            }
        }

        .wrapper {
            padding: 32px 32px 40px;
        }
    }
}

@media screen and (min-width: 705px) {
    .cross {
        width: 56px;
        height: 56px;
        padding: 16px;
        right: -12px;
        top: -12px;

        img {
            width: 24px;
            height: 24px;
        }

        /*  right: 40px;
        top: 40px; */
    }

    .Modal {
        .overlay {
            padding: var(--mg-lr);
        }

        .wrapper {
            padding: 40px 40px 80px;
            border-radius: 64px;
        }
    }
}

@media screen and (min-width: 1024px) {
    /*  .cross {
        right: 48px;
        top: 48px;
    } */

    .Modal {
        .wrapper {
            padding: 80px;
            height: auto;
            max-width: 1040px;
        }
    }
}

@keyframes append-animate {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media screen and (min-width: 430px) and (min-height: 900px) {
    .Modal {
        .wrapper {
            height: auto;
        }
    }
}