.SolanaToast {
    position: fixed;
    right: 40px;
    bottom: 40px;
    cursor: pointer;

    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
    padding: 16px;
    align-items: center;
    gap: 16px;
    display: flex;
    transition: all 0.3s ease;
    animation: appear 0.5s ease;

    span {
        color: black;
    }

    b {
        font-weight: 600;
    }

    .button {
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 48px;
        background: var(--primary-100, #06f);
        color: white;
        font-weight: 600;
        letter-spacing: 0.16px;
        text-transform: capitalize;
    }

    .icons {
        width: 48px;
        height: 48px;
        position: relative;

        .sol {
            position: absolute;
            left: 0;
            top: 0;
        }

        .ton {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    &.compressed {
        padding: 12px 24px;
        border-radius: 48px;

        .icons {
            height: auto;
            width: auto;
            display: flex;
            gap: 4px;

            .sol,
            .ton {
                position: static;
                width: 24px;
                height: 24px;
            }
        }

        .button {
            display: none;
        }
    }
}

@keyframes appear {
    from {
        opacity: 0;
        transform: translateX(120%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}
