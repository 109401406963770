.link {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.16px;
    text-transform: capitalize;
    padding: 8px 0px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 16px;
    color: inherit;
    align-items: center;
    justify-content: center;

    &.arrow {
        flex-shrink: 0;
        padding: 0;
        background-color: white;
        border-radius: 50%;
        box-shadow: none;
        transition: all 0.4s ease;
        color: var(--bg-100);
        width: 40px;
        height: 40px;

        &:hover {
            box-shadow: var(--button-gradient-blue);
            color: var(--bg-100);
            background-color: var(--color-white);
        }

        .arrow {
            width: 24px;
            height: 24px;
            color: var(--bg-100);
        }
    }

    &fixed {
        width: 56px;
        height: 56px;

        .arrow {
            width: 24px;
            height: 24px;
        }
    }

    &.blue {
        .arrow {
            color: var(--primary-100);
        }

        width: 40px;
        height: 40px;

        .arrow {
            position: relative;
            //top: -4px;
            width: 17px;
            height: 17px;
        }

        &:hover {
            box-shadow: none;
        }
    }
}

.wrapper {
    cursor: pointer;
}

@keyframes arrow {
    0% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(7px);
    }

    100% {
        transform: translateX(0px);
    }
}

@media screen and (min-width: 706px) {
    .link {
        &.blue {
            width: 48px;
            height: 48px;

            .arrow {
                // top: -2px;
                width: 20px;
                height: 20px;
            }
        }

        &.arrow {
            width: 48px;
            height: 48px;

            .arrow {
                // top: -2px;
                width: 20px;
                height: 20px;
            }
        }

        &fixed {
            width: 56px;
            height: 56px;

            .arrow {
                width: 24px;
                height: 24px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .link {
    }
}
