.fixed {
    right: 24px;
    bottom: 24px;
    position: fixed;
}

@media screen and (min-width: 706px) {
    .fixed {
        right: 32px;
        bottom: 32px;
    }
}

@media screen and (min-width: 1024px) {
    .fixed {
        right: 40px;
        bottom: 40px;
    }
}
